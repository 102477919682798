import $ from 'jquery'
import './scss/style.scss'

import Swiper from 'swiper';
import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import 'swiper/swiper.css';


// Open and close menu
$('header .btn-open').click(function(){
  const element = $(this).attr('id')
  $(`[data-item="${element}"]`).attr('open', true)
})

$('header .btn-close').click(function(){
  $('.slide-menu').attr('open', false)
})

$('.btn-modal img').click(function(){
  const has = $(this).parent().find('.modal').hasClass('active')
  if(!has) {
    $(this).parent().find('.modal').addClass('active')
  }
})

$('.modal .btn').click(function(){
  $(this).parent().removeClass('active')
})

// Filter Search
$('[type="text"]').attr( "autocomplete", "off" ); 

$('.cat-item').click(function(){
  const checked = $(this).find('input').is(':checked')

  if(checked) {
    $(this).addClass('active')
  } else {
    $(this).removeClass('active')
  }
})

$('.cat-item').each(function() {
  const checked = $(this).find('input').is(':checked')
  if(checked) {
    $(this).addClass('active')
  } else {
    $(this).removeClass('active')
  }
});

// Filter Programação - Reorder
$('.order-date').click(function(){
  const filtered = $(this).hasClass('filtered-date')

  if(filtered) {
    $(this).removeClass('filtered-date')
    $('.schedulepage .events').removeClass('filtered-date')
  } else {
    $(this).addClass('filtered-date')
    $('.schedulepage .events').addClass('filtered-date')
  }
})

$('.order-category').click(function(){
  const filtered = $(this).hasClass('filtered-category')

  if(filtered) {
    $(this).removeClass('filtered-category')
    $('.schedulepage .events').removeClass('filtered-category')
  } else {
    $(this).addClass('filtered-category')
    $('.schedulepage .events').addClass('filtered-category')
  }
})

// Filter Programação - Filter
$('.filter-item .btn-filter').click(function(){
  const date = $(this).attr('data-type')
  const clicked = $(this).hasClass('clicked')

  if(clicked) {
    $(`.filter-item .btn-filter`).removeClass('clicked')
    $(`.grid`).removeClass('hidden').addClass('show')

  } else {
    $(`.filter-item .btn-filter`).removeClass('clicked')
    $(this).addClass('clicked')

    $(`.grid`).removeClass('show').addClass('hidden')
    $(`.${date}`).addClass('show')
  }
})

// Hover menu
$( ".menu-item" ).hover(
  function() {
    const index =  $(this).index();
    $('.nav').addClass(`active active-${index}`);
    $( this ).addClass('active');
  }, function() {
    $('.nav').removeClass('active active-0 active-1 active-2 active-3 active-4');
    $( this ).removeClass('active');
  }
);

// Marqueee
(function () {
  const marquee = $("h2 .marquee");
  $(window).on("resize", onResize);

  onResize();
  function onResize() {
    startMarquee();
  }

  function startMarquee() {
    document.querySelector(".marquee").style.animationPlayState = 'running';
    marquee.css({ animationDuration: marquee.width() * .005 + "s" });
  }
})();


const swiper = new Swiper('.swiper', {
  modules: [Pagination, Autoplay],
  speed: 1500,
  loop: true,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
});

(function() {
  const breakpoint = window.matchMedia( '(max-width: 700px)' );
  let mySwiper;
  const breakpointChecker = function() {
    if ( breakpoint.matches === false ) {
	  if ( mySwiper !== undefined ) mySwiper.destroy( true, true );
	    return;
    } else if ( breakpoint.matches === true ) {
      return enableSwiper();
    }
  };

  const enableSwiper = function() {
    mySwiper = new Swiper ('.grid', {
      modules: [Navigation],
      slidesPerView: 1.2,
      spaceBetween: 10,
      speed: 1000,
      navigation: {
        prevEl: '.prev',
        nextEl: '.next',
      },
    });
  };
  breakpoint.addListener(breakpointChecker);
  breakpointChecker();
})();